<template>
  <div>
    <UIExit label="EXIT PICKUP MODE" />

    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import layoutConstants from '@src/constants/layout-constant.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import UIExit from '@src/components/UiElements/UIExit.vue'

export default {
  components: {
    UIExit,
  },
  data() {
    return {
      TENANT_ROLES,
    }
  },
  created() {
    this.setLeftbarContent({
      back: '',
      customeHeader: 'left-bar-class-list',
      SHOW_SECTION_DROPDOWN_TO: layoutConstants.showSectionDropdownTo,
      SHOW_CLASS_DROPDOWN_TO: layoutConstants.showClassDropdownTo,
      routes: [
        {
          id: '0',
          role: [TENANT_ROLES.PICKUP_PERSON],
          name: 'LB_ARdddd',
          route: { name: 'facility-user-active-request' },
        },

        {
          id: '2',
          role: [TENANT_ROLES.PICKUP_PERSON],
          name: 'LB_ALL_R',
          route: { name: 'facility-user-all-requests' },
        },
      ],
    })
  },
  methods: {
    ...mapActions('layout', ['setLeftbarContent']),
  },
}
</script>
